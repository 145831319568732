var site = site || {};
site.onLoadRpc = site.onLoadRpc || {};

site.onLoadRpc.defaults = {

  user_gnavData: {
    DOOR_DATA_LONGITUDE: null,
    DOOR_ID: null,
    SHADES: [],
    RENDERED_DOOR_DATA: null,
    FAVORITE_PRODUCTS: [],
    EMAIL_ADDRESS: null,
    DOOR_DATA_LATITUDE: null,
    PAST_PURCHASED_SKUS: {},
    IS_PRO: 0
  }

};
